<template>
  <div v-if="$store.getters.ready">
    <Header />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row justify-content-md-center mt-3">
            <div class="col-md-8 col-lg-6 col-xl-5">

              <div class="widget-holder rounded">
                <div class="widget-bg">
                  <div class="widget-body text-center">

                    <h2 class="mb-5 text-center">Email Verification</h2>

                    <div v-if="mode === 'check'">
                      <h4 class="mt-2 mb-0"><i class="fal fa-envelope-open-text fa-5x text-stroke-5 text-icon-gray"></i></h4>
                      <p class="mt-4 fs-18 fw-300 heading-font-family">You're almost there. We sent an email to<br><strong>{{ $auth.profile.email }}</strong></p>
                      <p class="mt-4 fs-18 fw-300 heading-font-family">Just click on the link in that email to verify your email address. If you don't see it, you may need to <strong>check your spam folder</strong>.</p>
                      <div class="form-group mx-3 px-5 pt-3 mb-2">
                        <button type="button" class="btn btn-block btn-rounded btn-md btn-primary text-uppercase fw-600 ripple" @click="resendEmail">Resend</button>
                        <button type="button" class="btn btn-block btn-rounded btn-md btn-default text-uppercase fw-600 ripple" @click="nextStep">Skip</button>
                      </div>
                    </div>

                    <div v-if="mode === 'success'">
                      <h4 class="mt-2 mb-0"><i class="fal fa-check-circle fa-5x text-stroke-5 text-icon-gray"></i></h4>
                      <p class="mt-4 fs-18 fw-300 heading-font-family">Thanks for verifying your email address.</p>
                      <div class="form-group mx-3 px-5 pt-3 mb-2">
                        <button type="button" class="btn btn-block btn-rounded btn-md btn-primary text-uppercase fw-600 ripple" @click="nextStep">Continue</button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import toast from '@/modules/toast';

export default {
  data() {
    return {
      mode: 'check', // check, success
    };
  },
  methods: {
    resendEmail() {
      this.$api.post('/profile/verify/send', {})
        .then(() => {
          toast.icon(this, 'success', 'fa-check');
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    async nextStep() {
      if (await this.$auth.isAuthenticated()) {
        // ensure profile is up to date
        await this.$auth.loadProfile();
        if (this.$auth.profile.update_billing) {
          this.$router.push('/profile/billing/update');
        } else {
          this.$router.push('/dashboard');
        }
      } else {
        this.$router.push('/login');
      }
    },
  },
  async mounted() {
    if (!await this.$auth.isAuthenticated() || this.$route.query.email || this.$route.query.code) {
      if (!this.$route.query.email || !this.$route.query.code) {
        this.$store.commit('error', 404);
        return;
      }
      // verify code
      try {
        await this.$api.post('/profile/verify', { email: this.$route.query.email, code: this.$route.query.code });
        this.mode = 'success';
      } catch {
        this.$store.commit('error', 404);
        return;
      }
    }
    this.$store.commit('ready', true);
  },
  components: {
    Header,
  },
};
</script>
